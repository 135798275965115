<template>
  <nav-view></nav-view>
  <chat />
  <router-view />
  
  <a href="#" class="scroll-top d-flex align-items-center justify-content-center">
    <font-awesome-icon :icon="['fas', 'arrow-up']" />
  </a>
  <footer-view />
  <notifications position="bottom right" width="100%"/>

  <div id="preloader" v-if="preloader"></div>
</template>

<script>
import "./assets/vendor/bootstrap/js/bootstrap";
import "./assets/vendor/glightbox/js/glightbox.min.js";
import "./assets/vendor/glightbox/js/glightbox";
import "./assets/vendor/isotope-layout/isotope.pkgd.min.js";
import "./assets/vendor/aos/aos.js";

import FooterView from "@/components/footer/FooterView.vue";
import NavView from "@/components/header/NavView.vue";
import Chat from "@/components/chat/Chat.vue";

import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'

export default {
  name: 'App',


  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch('getParams');
    })

    return {
      preloader: computed(() => store.state.utiuls.preloader),
    }
  },
  components: {
    FooterView,
    NavView,
    Chat,
  }
}
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");
@import "./assets/vendor/bootstrap/css/bootstrap.css";
@import "./assets/vendor/glightbox/css/glightbox.css";
@import "./assets/vendor/swiper/swiper-bundle.min.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import "./assets/vendor/aos/aos.css";
@import "./assets/css/main.css";
</style>


