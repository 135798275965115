<template>  
        <div class="col-lg-12">
            <div class="stars" v-for="( star, index) in stars" :key="index" @mouseover="toggle(index)" @mouseout="$emit('stars',index + 1)">
                <font-awesome-icon :class="['star-icon', [star.active ? 'isActive' : 'isDisable'] ]" :icon="['fas', 'star']" size="2xl"/>
            </div>
        </div>
</template>

<script>
import {ref } from 'vue'
export default {
    name: "StarComponent",

    setup() {
        const stars = ref([
            {active:true},
            {active:true},
            {active:true},
            {active:false},
            {active:false}
        ])  

        const toggle = (index) =>{
            stars.value[index].active = !stars.value[index].active

            if(stars.value[index].active){
                for (let i = 0; i <= 4; i++) {     
                     stars.value[i].active = false;   
                }
               for (let i = index; i >= 0; i--) {
                    stars.value[i].active = true;   
               }    
            }else{
                for (let i = index; i != stars.value.length; i++) {
                    stars.value[i].active = false;   
               }    
            }
        }
        return{
            toggle,
            stars
        }
        
    }

}
</script>

<style>
.isActive{
    color: var(--color-primary)
}

.isDisable{
    color:gray
}
.stars{
    display: inline-flex;
    margin: 10px;
}

.stars:hover{
    color: var(--color-primary);
    transform: scale(1.3);
    transition: 0.2s;
}

.star-icon:hover{
    color: var(--color-primary);
}


</style>