<template>
   <Breadcrub
   title="Ops!"
   pageName="404"
   />

   <div class="container">
    <h1>404</h1>
    <p>Ops! Parece que a página que você está procurando não existe.</p>
    <p>Você pode voltar para a <router-link class="" :to="{name:'home' }">Pagina Inicial</router-link> ou tentar pesquisar novamente.</p>
  </div>
</template>

<script>
import Breadcrub from '@/components/breadcrub/Breadcrub.vue';

export default{
    name: "NotFound",
    components: { Breadcrub }
}
</script>

<style scoped>
 body {
      background-color: #ebe5e5;
      font-family: Arial, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      color: #464545;
    }
 .container {
      max-width: 960px;
      margin: 0 auto;
      padding: 50px 15px;
      text-align: center;
    }

    h1 {
      font-size: 60px;
      margin-bottom: 10px;
      color: #f00606;
    }

    p {
      font-size: 24px;
      margin-bottom: 30px;
    }
    
</style>