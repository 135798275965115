import ProjetoService from "@/services/ProjetoService";

export default{
    state: {
        projetos:[
        ],
        select:{
            title:"",
            cliente:"",
            url:"",
            data:"",
            description:"",
            categories:[
                {id:"",name:""}
            ],
            galerias:[
                {id:"",name:"",descricao:"",url:""}
            ]

        }
    },
    getters: {
    },
    mutations: {
        setProjetos(context , data){
            context.projetos = data;
        },
        selectProjeto(context , data){
            context.select = data;
        }
    },
    actions: {
        getProjetos(context){
            context.dispatch('preloaderSet', true);
            ProjetoService.getAllProjetos().then((response)=>{
                context.commit('setProjetos', response.data);
            }).finally(context.dispatch('preloaderSet', false))
        },
        getProjeto(context, id){
            ProjetoService.getProjeto(id).then((response)=>{
                context.commit('selectProjeto', response.data);
            }).finally(context.dispatch('preloaderSet', false))
        }

    },
}