<template>
    <!-- ======= Testimonials Section ======= -->
    <section  id="testimonials" class="testimonials section-bg">
        <div  class="container" data-aos="fade-up">
            <div class="section-header">
                <h2>Testemunhos</h2>
                <p>Veja alguns testemunhos dos nosso clientes! </p>
            </div>
       
            <swiper
                class="d-lg-none"
                :modules="modules"
                :slides-per-view="1"
                :space-between="10"
                :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
                }"
                pagination
                @swiper="onSwiper"
                @slideChange="onSlideChange"
            >
           
                <swiper-slide  class="swiper-slide"  v-for="feedback in listFeedbacks" :key="feedback.id">
                    <FeedBackCard :name="feedback.name" :profession="feedback.profession" :photo="feedback.photo"
                        :message="feedback.message" :stars="feedback.stars" />
                </swiper-slide>
             
            </swiper>

            <swiper
                class="d-none d-lg-block"
                :modules="modules"
                :slides-per-view="2"
                :space-between="10"
                :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
                }"
                pagination
                @swiper="onSwiper"
                @slideChange="onSlideChange"
            >
           
                <swiper-slide  class="swiper-slide"  v-for="feedback in listFeedbacks" :key="feedback.id">
                    <FeedBackCard :name="feedback.name" :profession="feedback.profession" :photo="feedback.photo"
                        :message="feedback.message" :stars="feedback.stars" />
                </swiper-slide>
             
            </swiper>
        </div>
        <div class="col-md-12 text-center">           
            <ButtonView
                name="Deixar seu feedback"
                @onclick="feedback"
            />         
        </div>
    </section><!-- End Testimonials Section -->
</template>

<script>
/* eslint-disable */
import { useStore } from 'vuex';
import FeedBackCard from './feedbackCard/FeedBackCard.vue';
import ButtonView from '../button/ButtonView.vue'
import { computed, onMounted, ref } from 'vue';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import router from '@/router';

export default {
    name: "FeedBacksComponent",

    setup() {
        const store = useStore()

        onMounted(() => {
            store.dispatch('getFeedbacks');         
        })

        const onSwiper = (swiper) => {
            
        };
        const onSlideChange = () => {
          
        };

        const feedback = () => {
            const isAuth = store.state.auth.isAuthenticated;
            if(!isAuth){
                store.dispatch('login')  
            }else{
                router.push('/feedback')
            }              
        }

        return {
            listFeedbacks : computed(()=> store.state.feedbacks.feedbacks ),
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination],
            feedback
        }
    },

    components: {
        FeedBackCard,
        ButtonView,
        Swiper, SwiperSlide
    }
}
</script>