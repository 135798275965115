import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import bootstrap from './assets/vendor/bootstrap/js/bootstrap'
import Notifications from '@kyvg/vue3-notification'
import icons from './utius/icons'
import { register } from 'swiper/element/bundle';
// register Swiper custom elements

register();

import "jquery"
import 'glightbox';
import 'swiper/css';

import { AOS } from './assets/vendor/aos/aos' 
import "./assets/js/main.js";

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';



/* add icons to the library */
library.add(icons)

createApp(App).use(store)
    .use(router)
    .use(AOS)
    .use(library)  
    .use(bootstrap)
    .use(FontAwesomeIcon)
    .use(Notifications)
    .component('font-awesome-icon' ,FontAwesomeIcon)
    .mount('#app')
