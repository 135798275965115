import { 
    faUserSecret, 
     faArrowUp,
     faChevronRight, 
     faChevronLeft, 
     faMountainCity, 
     faArrowUpFromGroundWater, 
     faCompassDrafting,
     faStar,
     faPaste,
     faHelmetSafety 
    } from '@fortawesome/free-solid-svg-icons'

import { faTwitter, faFacebook, faInstagram, faLinkedin, } from '@fortawesome/free-brands-svg-icons';

export default{
    faUserSecret, 
    faStar,
    faArrowUp,
    faChevronRight, 
    faChevronLeft, 
    faMountainCity, 
    faArrowUpFromGroundWater, 
    faCompassDrafting,
    faPaste,faHelmetSafety,
    faTwitter, 
    faFacebook, 
    faInstagram, 
    faLinkedin,
}