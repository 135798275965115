<template>
    <div class="service-item  position-relative">
        <div class="icon">
            <font-awesome-icon  style="height: 40px;"  :icon="['fas',icone == '' ? 'helmet-safety' : icone]" />
        </div>
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
        <a v-if="url != ''" :href="url" class="readmore stretched-link">Saiba mais <i class="bi bi-arrow-right"></i></a>
    </div>
</template>

<script>
export default {
    name: "ServiceCard",

    props:{
        title:{
            require: true,
            type: String
        },
        description:{
            require: true,
            type: String
        },
        url:{
            require: true,
            type: String
        },
        icone:{
            require: true,
            type: String,
            default:"paste"
        }
    }
}
</script>