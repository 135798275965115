import ParametrosService from "@/services/ParametrosService"

export default{
    state: {
        dataSite: {
            title: "Pconsult",
            description:"",
            addres: "",
            number: "",
            cep: "",
            city: "",
            uf: "Ba",
            phones: "",
            emails: ""
        }
            
    },
    getters: {
    },
    mutations: {
        setParams(context , data){
            context.dataSite.title = data[0].title;
            context.dataSite.description = data[0].description;
            context.dataSite.addres = data[0].addres;
            context.dataSite.number = data[0].number;
            context.dataSite.cep = data[0].cep;
            context.dataSite.city = data[0].city;
            context.dataSite.uf = data[0].uf;
            context.dataSite.phones = data[0].phones;
            context.dataSite.emails = data[0].emails;

        }
    },
    actions: {
        getParams(context){
            context.dispatch('preloaderSet', true);
            
            ParametrosService.getAllParams().then((response)=>{          
                context.commit('setParams', response.data);
            })
            .finally(context.dispatch('preloaderSet', false))
        }
    },
}