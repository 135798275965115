export default{
    state: {
        preloader:true           
    },
    getters: {
    },
    mutations: {
        setPreloader(context , state){
            context.preloader = state;
        }
    },
    actions: {
        preloaderSet(context , state){
            context.commit('setPreloader' ,state)
        }
    },
}