import axios from "axios";
import { URL_API } from "@/config";

export default class ProjetoService{

    static async getAllProjetos(){
        return axios.get(`${URL_API}/projetos`)
     }

     static async getProjeto(id){
        return axios.get(`${URL_API}/projetos/${id}`)
     }
}