<template>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="hero">

        <div class="info d-flex align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6 text-center">
                        <h2 data-aos="fade-down">Bem vindo(a) <span class="title">{{ title }}</span></h2>
                        <p class="d-none d-lg-block" data-aos="fade-up">{{ description }}</p>
                        <a data-aos="fade-up" data-aos-delay="200" href="#cotacao" class="btn-get-started">Cotação</a>
                    </div>
                </div>
            </div>
        </div>

        <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">

            <div :class="['carousel-item', index == 0 ? 'active' : '']" v-for="(image, index) in images" :key="image.url"> <img :src=" require('@/assets/img/'+image.url) "> </div>

            <a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                <span class="carousel-control-next-icon bi bi-chevron-left" aria-hidden="true"></span>

            </a>

            <a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
            </a>

        </div>

    </section><!-- End Hero Section -->
</template>

<script>


export default{
    name:"CarroucelView",

    props: {
        title: {
            require: true,
            type: String
        },
        description: {
            require: true,
            type: String
        },
        images: {
            require: true,
            type: Object
        }
    },
    setup() {
   
  },
}
</script>