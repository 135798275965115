<template>
    <section  id="partners" class="partners section-bg" v-if="logos.length > 0">
        <div  class="container" data-aos="fade-up">
            <div class="section-header">
                <h2>Nossos Clientes</h2>
            </div>

            <swiper
                class="d-none d-lg-block"   
                :modules="modules"         
                :slides-per-view="3"
                :space-between="5"
                :lazy="true"
                :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
                }"
            >         
                <swiper-slide   v-for="logo in logos" :key="logo.id">
                    <PartersItem
                        :url="logo.url"
                        :id="logo.id"
                    />
                </swiper-slide>
             
            </swiper>

            <swiper
                class="d-lg-none"
                :modules="modules"
                :slides-per-view="1"
                :space-between="5"
                :lazy="true"
                :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
                }"
            >
           
                <swiper-slide  class="swiper-slide" v-for="logo in logos" :key="logo.id">
                    <PartersItem
                        :url="logo.url"
                    />
                </swiper-slide>
             
            </swiper>
        </div>

    </section>
</template>

<script>
/* eslint-disable */
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

import { Navigation, Pagination} from 'swiper/modules';
import PartersItem from './card/PartersItem.vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    name: "Partners",

    setup(){
        const store = useStore()

        onMounted(() => {
            store.dispatch('getAllParters');         
        })

        return{
            logos: computed(()=> store.state.parters.parters ),
            modules: [Navigation, Pagination],
        }
    },
    components: {
        Swiper, 
        SwiperSlide,
        PartersItem
    }
}
</script>

<style>

@media screen {
    
}


</style>

