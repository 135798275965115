<template>
    <!-- ======= Get Started Section ======= -->
    <section id="cotacao" class="get-started section-bg">
        <div class="container">
            <div class="row justify-content-between gy-4">
                <div class="col-lg-6 d-flex align-items-center" data-aos="fade-up">
                    <div class="content">
                        <h3>Entre em contato conosco e obtenha a cotação para seu projeto.</h3>
                        <p>A Pconsult & Sousa SST é uma empresa de consultoria especializada em prevenção e combate a
                            incêndios e Segurança do trabalho.
                            Nós oferecemos uma ampla gama de serviços para ajudar empresas e organizações a garantir a
                            segurança de suas instalações e proteger seus ativos contra incêndios.
                        </p>
                    </div>
                </div>
                <div class="col-lg-5" data-aos="fade">
                    <form @submit.prevent="sendForm" method="post" class="php-email-form">
                        <h3>Entre em Contato</h3>
                        <p>A pconsult esta de prontidão para atende-lo, envie sua solicitação de cotação e retornaremos o
                            mais breve possivel.</p>
                        <div class="row gy-3">
                            <div class="col-md-12">
                                <input type="text" v-model="msg.name" name="name" class="form-control" placeholder="Nome"
                                    minlength="8" required>
                            </div>

                            <div class="col-md-12">
                                <input type="text" v-model="msg.assunto" name="assunto" class="form-control" min="4"
                                    placeholder="Assunto" required>
                            </div>

                            <div class="col-md-12 ">
                                <input type="email" v-model="msg.email" class="form-control" name="email"
                                    placeholder="E-mail" required>
                            </div>

                            <div class="col-md-12">
                                <input type="tel" pattern="(\+55)?\s?\(\d{2}\)\s?\d{4,5}\-\d{4}" v-model="msg.phone"
                                    class="form-control" name="phone" placeholder="+55 (61) xxxxx-xxxx ou (61) xxxxx-xxxx"
                                    required>
                            </div>

                            <div class="col-md-12">
                                <textarea class="form-control" minlength="16" v-model="msg.message" name="message" rows="6"
                                    placeholder="Mensagem" required></textarea>
                            </div>
                            <div class="col-md-12 text-center">
                                <div class="loading">Carregando</div>
                                <div class="error-message"></div>
                                <div class="sent-message">Sua solicitação foi enviada com sucesso Obrigado(a)!</div>

                                <button :disabled="preloader" type="submit">
                                    <span v-if="!preloader">Obter Cotação</span>
                                    <span v-else>Enviando...</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div><!-- End Quote Form -->
            </div>
        </div>
    </section><!-- End Get Started Section -->

    <div id="preloader" v-if="preloader"></div>
</template>

<script>
/* eslint-disable */
import { computed, ref } from 'vue'
import { useStore } from 'vuex';
import { notify } from "@kyvg/vue3-notification";

export default {
    name: "QuoteView",

    setup() {
        const store = useStore();
        
        const msg = ref({
            name: "",
            subject: "",
            email: "",
            phone: "",
            message: ""

        })
        const sendForm = () => {
            if (msg.value.name == "" || msg.value.assunto == "" || msg.value.email == "" || msg.value.phone == "" || msg.value.message == "") {

                notification.notify({
                    title: "Falha",
                    text: '<strong>Preecha todos os campos.</strong>',
                    duration: 5000,
                    type: 'warn',
                });
                return;
            }
            store.dispatch('sendMensagem', msg.value).then(() => {
                msg.value.name = ""
                msg.value.assunto = ""
                msg.value.email = ""
                msg.value.phone = ""
                msg.value.message = ""
                notify({
                    title: "Sucesso!",
                    text: "Sua mensagem foi enviada !",
                    type: "success"
                });
            }).catch(() => {
                notify({
                    title: "Falha ao enviar mensagem!",
                    text: '<strong>Falha inesperada por favor tente outro meio de contato.</strong>',
                    duration: 5000,
                    type: 'error',
                });
            });

        }

        return {
            preloader: computed(() => store.state.utiuls.preloader),
            sendForm,
            msg
        }
    }
}
</script>