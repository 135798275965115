import instance from "@/api";

export default class FeedBacksService {

    static async getAllFeedbacks() {
        return instance.get("/feedback");
    }

    static async sendFeedback(feedback){
        return instance.post("/feedback",feedback);
    }
}