import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DetalheProjeto from '@/views/detalhesProjeto/DetalheProjeto.vue'
import SobreNos from '@/views/AboutView.vue'
import NotFound from '@/views/notFound/NotFound.vue'
import ServicosView from '@/views/servicos/ServicosView.vue'
import ContatoView from '@/views/contato/ContatoView.vue'
import FeedBackView from '@/views/feedback/FeedBackView.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/detalhe-projeto/:idProjeto',
    name: 'detalheProjeto',
    component: DetalheProjeto,
    props: true
  },
  {
    path: '/sobre',
    name: 'sobreNos',
    component: SobreNos,
  },
  {
    path: '/servicos',
    name: 'servicos',
    component: ServicosView,
  },
  {
    path: '/contato',
    name: 'contato',
    component: ContatoView,
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: FeedBackView,
    meta: { requiresAuth: true },
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]
/* eslint-disable */
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from ,savedPosition){
    return{top:0}
  }
})

router.beforeEach((to, from)=>{
  const isAuth = store.state.auth.isAuthenticated;

  if(to.meta.requiresAuth && !isAuth){
    return {
      name: 'home',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    }
  }
})

export default router
