import { createStore } from 'vuex'
import home from './modules/home'
import utiuls from './modules/utiuls'
import servicos from './modules/servicos'
import feedbacks from './modules/feedbacks'
import projetos from './modules/projetos'
import categorias from './modules/categorias'
import galeria from './modules/galeria'
import mensagens from './modules/mensagens'
import auth from './modules/auth'
import team from './modules/team'
import parters from './modules/parters'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    home,
    utiuls,
    servicos,
    feedbacks,
    projetos,
    categorias,
    galeria,
    mensagens,
    auth,
    team,
    parters
  }
})
