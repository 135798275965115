<template>
    <div class="member-img">
        <img :src="photo" class="img-fluid" :alt="photo">
        <div class="social">
            <a v-if="twitter" :href="twitter"><i class="bi bi-twitter"></i></a>
            <a v-if="facebook" :href="facebook"><i class="bi bi-facebook"></i></a>
            <a v-if="instagram" :href="instagram"><i class="bi bi-instagram"></i></a>
            <a v-if="linkedin" :href="linkedin"><i class="bi bi-linkedin"></i></a>
        </div>
    </div>
    <div class="member-info text-center">
        <h4>{{ name }}</h4>
        <span>{{ profession }}</span>
        <p>{{ description }}</p>
    </div>
</template>

<script>
export default {
    name: "TeamCardView",

    props: {
        name: {
            require: true
        },
        profession: {
            require: true
        },
        description: {
            require: true
        },
        photo: {
            require: true
        },
        twitter: {

        },
        facebook: {

        },
        instagram: {

        },
        linkedin: {

        },

    }
}
</script>