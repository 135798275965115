<template>
    <!-- ======= Galeria Section ======= -->
    <section id="projects" class="projects">
        <div class="container" >

            <div class="section-header">
                <h2>Galeria de Projetos</h2>
                <p>Aqui esta a galeria com fotos da execução de alguns dos nossos projetos elaborados</p>
            </div>

            <div  data-portfolio-filter="*" data-portfolio-layout="masonry"
          data-portfolio-sort="original-order">


                <ul class="portfolio-flters" data-aos="fade-up" data-aos-delay="100">
                    <li data-filter="*" class="filter-active">Todos</li>

                    <li v-for="categoria in categorias" :key="categoria.id" :data-filter="'.filter-' + categoria.id">{{
                        categoria.name }}</li>

                </ul><!-- End Projects Filters -->

                <div class="row gy-6 portfolio-container" data-aos="fade-up" data-aos-delay="200">              
                        <GaleryCard
                        v-for="imagem in imagens" :key="imagem.id"
                        
                        :id="imagem.projeto_id"
                        :title="imagem.name"
                        :description="imagem.descricao"
                        :category="imagem.categoria.id"
                        :image="imagem.url"
                    />
                </div><!-- End Projects Container -->

            </div>

        </div>
    </section><!-- End Our Projects Section -->
</template>

<script>
/* eslint-disable */
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import GaleryCard from './galeryCard/GaleryCard.vue';

export default {
    name: "ProjectsGaleryView",

    setup() {
        const store = useStore();

        onBeforeMount(() => {
            store.dispatch("getCategorias")
            store.dispatch("getGalerias")
        })

        return {
            categorias: computed(() => { return store.state.categorias.categorias }),
            imagens: computed(() => { return store.state.galeria.galeria })
        }
    },
    components: {
        GaleryCard
    }
}
</script>

<style>
@import "@/assets/vendor/aos/aos.css";
</style>