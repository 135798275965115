<template>
  <!-- ======= Header ======= -->
  <header id="header" class="header d-flex align-items-center">
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">


      <router-link class="navbar logo d-flex align-items-center " :to="{ name: 'home' }">

        <img src="@/assets/img/favicon.png" alt="">
        <h1 style="border-bottom: 1px; border-color: red; border-bottom-style: solid; border-bottom-left-radius: 1px;">{{
          title }}<span>.</span></h1>
      </router-link>


      <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
      <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
      <nav id="navbar" class="navbar">
        <ul>
          <li><router-link class="" :to="{ name: 'home' }">Home</router-link></li>
          <li><router-link class="" :to="{ name: 'sobreNos' }">Sobre</router-link></li>
          <li><router-link class="" :to="{ name: 'servicos' }">Serviços</router-link></li>
          <li><router-link class="" :to="{ name: 'contato' }">Contatos</router-link></li>
        </ul>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: "NavView",

  setup() {
    const store = useStore()


    return {
      title: computed(() => store.state.home.dataSite.title),
    }
  }
}
</script>