<template>
    <div class="chat">
      
    </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Chat",
    setup() {
        onMounted(() => {
                // eslint-disable-next-line no-unused-vars
                var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/66561a499a809f19fb3656ad/1hv05dbti';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();
        })
    }
};
</script>

<script type="text/javascript">
   
</script>

<style scoped>
/* Your component styles go here */
</style>