import CategoriaService from "@/services/CategoriaService";

export default{
    state: {
        categorias:[
        ]
    },
    getters: {
    },
    mutations: {
        setCategorias(context , data){
            context.categorias = data;
        }
    },
    actions: {
        getCategorias(context){
            context.dispatch('preloaderSet', true);
            CategoriaService.listAll().then((response)=>{
                context.commit('setCategorias', response.data);
            }).finally(context.dispatch('preloaderSet', false))
        }
    }
}