import TeamService from "@/services/TeamService";

export default{
    state: {
        colaboradores:[
        ]
    },
    getters: {
    },
    mutations: {
        setColaboradores(context , data){
            context.colaboradores = data;
        }
    },
    actions: {
        getColaboradores(context){
            context.dispatch('preloaderSet', true);
            TeamService.get().then((response)=>{
                context.commit('setColaboradores', response.data);
            }).finally(context.dispatch('preloaderSet', false))
        }
    },
}