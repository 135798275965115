<template>
  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">

    <div class="footer-content position-relative">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="footer-info">
              <h3>{{ dataSite.title }}</h3>
              <p>
                <strong>Endereço:</strong> {{ dataSite.addres }} <br>
                <strong>Nº:</strong> {{ dataSite.number }} <br>
                <strong>Cep:</strong> {{ dataSite.cep }} <br>
                <strong>Cidade:</strong> {{ dataSite.city }} - {{ dataSite.uf }}<br><br>

                <strong>Telefone(s):</strong><br> <span v-for="phone in dataSite.phones " :key="phone">{{ phone
                }}<br></span><br>
                <strong>E-mails:</strong>
              </p>

              <div class="emails" v-for="email in dataSite.emails" :key="email.id">
                <strong>{{ email.name }}:</strong> {{ email.email }}<br>
              </div>


              <div class="social-links d-flex mt-3">
                <a href="#" class="d-flex align-items-center justify-content-center"><font-awesome-icon
                    :icon="['fab', 'twitter']" /></a>
                <a href="#" class="d-flex align-items-center justify-content-center"><font-awesome-icon
                    :icon="['fab', 'facebook']" /></a>
                <a href="#" class="d-flex align-items-center justify-content-center"><font-awesome-icon
                    :icon="['fab', 'instagram']" /></a>
                <a href="#" class="d-flex align-items-center justify-content-center"><font-awesome-icon
                    :icon="['fab', 'linkedin']" /></a>
              </div>
            </div>
          </div><!-- End footer info column-->

          <div class="col-lg-2 col-md-3 footer-links">
            <h4>Links Úteis</h4>
            <ul>
              <li><router-link class="" :to="{name:'home' }">Home</router-link></li>
              <li><router-link class="" :to="{name:'servicos' }">Serviços</router-link></li>
              <li><a href="#">Termos de serviço</a></li>
              <li><a href="#">Política de privacidade</a></li>
            </ul>
          </div><!-- End footer links column-->
        </div>
      </div>
    </div>

    <div class="footer-legal text-center position-relative">
      <div class="container">
        <div class="copyright">
          &copy; Copyright <strong><span>{{ dataSite.title }}</span></strong>. Todos os direitos reservados.
        </div>
        <div class="credits">
          By <a href="https://nataciotecnologia.com.br">Natácio Tecnologia</a><br> Template By <a
            href="https://bootstrapmade.com">BootstrapMade.com</a>
        </div>
      </div>
    </div>

  </footer>
  <!-- End Footer -->
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: "FooterView",

  setup() {
    const store = useStore();

    return {
      dataSite: computed(() => store.state.home.dataSite),
    }
  },
}
</script>

<style></style>