import ServicosService from "@/services/ServicosService";

export default{
    state: {
        servicos:[

        ]
    },
    getters: {
    },
    mutations: {
        setServicos(context , data){
            context.servicos = data;
        }
    },
    actions: {
        getServices(context){
            context.dispatch('preloaderSet', true);
            ServicosService.getAllServices().then((response)=>{
                context.commit('setServicos', response.data);
            }).finally(context.dispatch('preloaderSet', false))
        }
    },
}