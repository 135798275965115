<template>
    <div class="feedback">
        <Breadcrub title="Deixe seu feedback" pageName="feedback" :bgImage="require('@/assets/img/feedback.jpg')" />
    </div>
    <section class="get-started section-bg">
        <div class="container" data-aos="fade-up" data-aos-delay="100" v-if="isAuthenticated">
            <div class="col-lg-12 php-email-form text-center feedback-item">

                <img :src="user.photoURL" class="feedback-img" :alt="user.photoURL">
                <h3>{{ user.name }}</h3>

                <div class="row gy-6">
                    <div class="col-md-12 text-center">          
                        <StartComponent
                            @stars="stars"
                        ></StartComponent>
                    </div>
                </div>
                <form @submit.prevent="sendFeedback" method="post" class="php-email-form text-center">
                    <div class="row gy-3">

                        <div class="col-md-12">
                            <input type="text" v-model="msg.profession" name="profession" class="form-control" min="4"
                                placeholder="Profissão" required>
                        </div>

                        <div class="row gy-12">
                            <textarea class="form-control" name="message" rows="2" v-model="msg.message"
                                placeholder="digite seu feedback" maxlength="100" minlength="16" required></textarea>
                        </div>
                        <div class="text-center"><button type="submit">Enviar feedback</button></div>
                    </div>
                </form>
                
            </div><!-- End Contact Form -->
         </div>
    </section>
</template>

<script>
import Breadcrub from '@/components/breadcrub/Breadcrub.vue';
import StartComponent from '../../components/star/StartComponent.vue';
import { notify } from "@kyvg/vue3-notification";
import { computed, ref } from 'vue'
import { useStore } from 'vuex';

export default {
    name: "FeedBackView",

    setup() {
        const store = useStore();
        let userData =  store.state.auth.userData;

        const msg = ref({
            message: "",
            name: userData.name,
            profession: "",
            email: userData.email,
            photo: userData.photoURL,
            stars: 3
        })

        const stars = (stars) =>{
            msg.value.stars = stars;
        }

        const sendFeedback = () =>{
            store.dispatch('newFeedback',msg.value).then(()=>{
                notify({
                    title: "Sucesso!",
                    text: "Seu feedback foi enviado com sucesso !",
                    type: "success"
                });

                msg.value = {
                    message: "",
                    name: "",
                    profession: "",
                    email: "",
                    photo: "",
                    stars: 3
                }
                
                store.dispatch('logout')
            }).catch((e)=>{
                notify({
                    title: "Falha ao enviar feedback!",
                    text: '<strong>'+ e +' </strong>',
                    duration: 5000,
                    type: 'error',
                });

            });
        }
        return {
            msg,
            stars,
            sendFeedback,
            user : computed(()=> store.state.auth.userData ),
            isAuthenticated : computed(()=> store.state.auth.isAuthenticated ),
        }
    },

    components: {
        Breadcrub,
        StartComponent,
    }
}
</script>

<style scoped>
.feedback-img {
  width: 150px;
  border-radius: 10px;
  border: 6px solid #fff;
  position: relative;
}

.feedback-img:hover{
    transform: scale(1.2);
    transition: 0.5s;
}

.feedback-item h3 {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #000;
}

.feedback-item{
    border-radius: 10px;
}

.feedback-item textarea{
    border-radius: 10px;
    margin: 10px;
}

.feedback-item input{
    border-radius: 10px;
}

.feedback-item button{
    margin: 10px;
}

button {
  background: var(--color-primary);
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 5px;
}

button:hover {
  background: rgba(254, 185, 0, 0.8);
}
</style>