<template>
  <carroucel-view :title="title" :description="description" :images="images"></carroucel-view>

  <main id="main">
   
    <quote-view></quote-view>
    <ProjectsGaleryView/>
    <services-view></services-view>
    <projects-view></projects-view>  
    <FeedBacksComponent></FeedBacksComponent>
    <Partners></Partners>

    <div id="preloader" v-if="preloader"></div>
    
  </main><!-- End #main -->

  
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

import QuoteView from "@/components/quote/QuoteView.vue"
import ProjectsView from '@/components/projects/ProjectsView.vue'
import CarroucelView from '@/components/carroucel/CarroucelView.vue'
import ServicesView from '@/components/services/ServicesView.vue'
import ProjectsGaleryView from '@/components/projectsGalery/ProjectsGaleryView.vue'
import FeedBacksComponent from '@/components/feedbacks/FeedBacksComponent.vue'
import Partners from '@/components/partners/Partners.vue'

export default {
  name: 'HomeView',
  setup() {
    const store = useStore()
    var ismonted = false;

    return {
      title: computed(() => store.state.home.dataSite.title),
      description: computed(() => store.state.home.dataSite.description) ,
      preloader: computed(() => store.state.utiuls.preloader),
      images: [
        { url: "hero-carousel/hero-carousel-1.jpg", isActive: true },
        { url: "hero-carousel/hero-carousel-2.jpg", isActive: false },
        { url: "hero-carousel/hero-carousel-3.jpg", isActive: false },
        { url: "hero-carousel/hero-carousel-4.jpg", isActive: false },
      ],
      ismonted
    }
  },
  components: {
    QuoteView,
    ProjectsView,
    CarroucelView,
    ServicesView,
    ProjectsGaleryView,
    FeedBacksComponent,
    Partners
  }
}

</script>

<style>
span .title::first-letter {
  color: orange;
}
</style>
