<template>
    <Breadcrub
     title="Serviços"
     pageName="Serviços"
    />

    <ServicesView/>

    <FeedBacksComponent></FeedBacksComponent>
    
</template>

<script>
import Breadcrub from '@/components/breadcrub/Breadcrub.vue';
import FeedBacksComponent from '@/components/feedbacks/FeedBacksComponent.vue';
import ServicesView from '@/components/services/ServicesView.vue';

export default{
    name: "ServicosView",
    components: { Breadcrub, ServicesView, FeedBacksComponent }
}
</script>