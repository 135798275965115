<template>
    <Breadcrub title="Contatos" pageName="Contatos" />
    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
        <div class="container" data-aos="fade-up" data-aos-delay="100">

            <div class="row gy-4">
                <div class="col-lg-6">
                    <div class="info-item  d-flex flex-column justify-content-center align-items-center">
                        <i class="bi bi-map"></i>
                        <h3>Nosso endereço</h3>
                        <p>{{ dataSite.addres }}, Nº {{ dataSite.number }}, {{ dataSite.city }}, {{ dataSite.uf }} {{
                            dataSite.cep }}</p>
                    </div>
                </div><!-- End Info Item -->

                <div class="col-lg-3 col-md-6">
                    <div class="info-item d-flex flex-column justify-content-center align-items-center">
                        <i class="bi bi-envelope"></i>
                        <h3>E-mails</h3>
                        <p v-for="email in dataSite.emails" :key="email.id">{{ email.email }}</p>
                    </div>
                </div><!-- End Info Item -->

                <div class="col-lg-3 col-md-6">
                    <div class="info-item  d-flex flex-column justify-content-center align-items-center">
                        <i class="bi bi-telephone"></i>
                        <h3>Telefones</h3>
                        <p v-for="tel in dataSite.phones" :key="tel">{{ tel }}</p>
                    </div>
                </div><!-- End Info Item -->

            </div>

            <div class="row gy-4 mt-1">

                <div class="col-lg-6 ">
                    <iframe
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=-14.874183254308349,%20-40.84712291794926+(Nosso%20endere%C3%A7o)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
                </div><!-- End Google Maps -->

                <div class="col-lg-6">
                    <form @submit.prevent="sendForm" method="post" role="form" class="php-email-form">
                        <div class="row gy-4">
                            <div class="col-lg-6 form-group">
                                <input type="text" name="name" class="form-control" id="name" minlength="8" v-model="msg.name"
                                    placeholder="Seu nome" required>
                            </div>
                            <div class="col-lg-6 form-group">
                                <input type="email" class="form-control" name="email" id="email" v-model="msg.email"
                                    placeholder="Seu e-mail" required>
                            </div>

                            <div class=" form-group">
                                <input type="tel" pattern="(\+55)?\s?\(\d{2}\)\s?\d{4,5}\-\d{4}" v-model="msg.phone"
                                    class="form-control" name="phone" placeholder="+55 (61) xxxxx-xxxx ou (61) xxxxx-xxxx"
                                    required>
                            </div>

                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" name="subject" id="subject" minlength="4" v-model="msg.subject"
                                placeholder="Assunto" required>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" name="message" rows="5" v-model="msg.message"
                                placeholder="Messagem" minlength="16" required></textarea>
                        </div>
                        <div class="my-3">
                            <div class="loading">Loading</div>
                            <div class="error-message"></div>
                            <div class="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div class="text-center"><button type="submit">Enviar mensagem</button></div>
                    </form>
                </div><!-- End Contact Form -->

            </div>

        </div>
    </section><!-- End Contact Section -->
</template>

<script>
/* eslint-disable */
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

import Breadcrub from '@/components/breadcrub/Breadcrub.vue';
import { useNotification } from '@kyvg/vue3-notification';
import { notify } from "@kyvg/vue3-notification";

export default {
    name: "ContatoView",

    setup() {
        const store = useStore();

        const msg = ref({
            name: "",
            subject: "",
            email: "",
            phone: "",
            message: ""

        })



        const sendForm = () => {
            if (msg.value.name == "" || msg.value.assunto == "" || msg.value.email == "" || msg.value.phone == "" || msg.value.message == "") {

                notification.notify({
                    title: "Falha",
                    text: '<strong>Preecha todos os campos.</strong>',
                    duration: 5000,
                    type: 'warn',
                });
                return;
            }
            store.dispatch('sendMensagem', msg.value).then(() => {
                console.log("Aqui")
                msg.value.name = ""
                msg.value.subject = ""
                msg.value.email = ""
                msg.value.phone = ""
                msg.value.message = ""

                notify({
                    title: "Sucesso!",
                    text: "Sua mensagem foi enviada !",
                    type: "success"
                });
            }).catch(() => {
                notify({
                    title: "Falha ao enviar mensagem!",
                    text: '<strong>Falha inesperada por favor tente outro meio de contato.</strong>',
                    duration: 5000,
                    type: 'error',
                });
            });

        }


        return {
            dataSite: computed(() => store.state.home.dataSite),
            sendForm,
            msg
        }

    },

    components: { Breadcrub }
}
</script>