<template>
       <!-- ======= Breadcrumbs ======= -->
       <div class="breadcrumbs d-flex align-items-center" :style="{ backgroundImage: bgImage !='' ? 'url('+bgImage+')'  : 'url('+require('@/assets/img/breadcrumbs-bg.jpg')+')' }">
        <div class="container position-relative d-flex flex-column align-items-center" data-aos="fade">
            <h2>{{ title }}</h2>
            <ol>
                <li><router-link class="active" :to="{ name: 'home' }">Home</router-link></li>
                <li>{{ pageName }}</li>
            </ol>

        </div>
    </div><!-- End Breadcrumbs -->
</template>

<script>

export default{
    name:"BreadCrub",

    props:{
        bgImage:{  
            type: String   
        },
        pageName:{
            require: true,
            type: String
        },
        title:{
            require: true,
            type: String
        }

    }
}
</script>

<style>
.breadcrumbs{
    
}
</style>