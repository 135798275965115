<template>
  

    <BreadCrub
        :title="projeto.title"
        pageName="Detalhes do projeto"
        :bgImage="[projeto.galerias.length > 0 ? projeto.galerias[0].url : require('@/assets/img/constructions-1.jpg')]"
    />

    <!-- ======= Projet Details Section ======= -->
    <section id="project-details" class="project-details">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
            
            <FotosProjeto v-if="projeto.galerias" :imagens="projeto.galerias" />

            <div class="row justify-content-between gy-4 mt-4">

                <div class="col-lg-8">
                    <div class="portfolio-description">
                        <h2>Saiba mais sobre esse projeo</h2>
                        <p>{{ projeto.description }}</p>

                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="portfolio-info">
                        <h3>Informações do projeto</h3>
                        <ul>
                            <li><strong>Categoria</strong> <span v-for="categoria in projeto.categories "
                                    :key="categoria.id">{{ categoria.name }}</span></li>
                            <li><strong>Cliente</strong> <span>{{ projeto.cliente }}</span></li>
                            <li><strong>Data do projeto</strong> <span>{{ projeto.data }}</span></li>
                            <li v-if="projeto.url"><strong>Url do projeto</strong> <a :href="projeto.url">{{ projeto.url
                            }}</a>
                            </li>

                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </section><!-- End Projet Details Section -->

    <div id="preloader" v-if="preloader"></div>
</template>

<script>
/* eslint-disable*/
import { useStore } from 'vuex';
import FotosProjeto from './partial/FotosProjeto.vue';
import { computed, onMounted } from 'vue';
import BreadCrub from '@/components/breadcrub/Breadcrub.vue'

export default {
    name: "DetalheProjeto",

    props: {
        idProjeto: {
            require: true,
        }
    },

    setup(props) {
        const store = useStore();

        onMounted(() => {
            console.log(props.idProjeto)
            store.dispatch('getProjeto', props.idProjeto);
        })

        return {
            projeto: computed(() => { return store.state.projetos.select }),
            preloader: computed(() => store.state.utiuls.preloader),
        }

    },



    components: {
        FotosProjeto,
        BreadCrub
    }

}
</script>