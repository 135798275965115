<template>
    <router-link class="card-item" :to="{ path: '/detalhe-projeto/'+ idProject}">
        <div class="row">
           
            <div class="col-xl-5">
                <div class="card-bg" :style="{
                    backgroundImage: 'url(' + image + ')'
                }"></div>
            </div>
            <div class="col-xl-7 d-flex align-items-center">
                <div class="card-body">
                    <h4 class="card-title">{{ title }}</h4>
                    <p>{{ description }}</p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "ProjectView",

    props: {
        title: {
            require: true,
            type: String
        },
        description: {
            require: true,
            type: String
        },
        image: {
            require: true,
        },
        idProject:{
            require: true,
            type: Number
        }
    }
}
</script>