<template>
  <div class="position-relative h-100">

    <div class="slides-1 portfolio-details-slider swiper">
      <div class="swiper-wrapper align-items-center">
        <Swiper :navigation="true" :modules="modules" :pagination="{
          dynamicBullets: true
        }">
          <swiper-slide v-for="image in imagens" :key="image.id" class="swiper-slide">
            <img :src="image.url" :alt="image.url">
          </swiper-slide>
        </Swiper>
      </div>
    </div>



  </div>
</template>

<script>
import 'swiper/css';
import 'swiper/css/navigation';

import 'swiper/css/scrollbar';


import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination} from 'swiper/modules';

export default {
  name: "FotosProjeto",

  props: {
    imagens: {
      require: true
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {

    return {
      modules: [Navigation , Pagination]
    }
  }
}
</script>