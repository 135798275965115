<template>
    <div class="col-lg-4 col-md-6 portfolio-item" :class="'filter-' + category">
        <div class="portfolio-content h-100">
            <img :src="image" class="img-fluid" :alt="image">
            <div class="portfolio-info">
                <h4>{{ title }}</h4>
                <p>{{ description }}</p>
                <a :href="image" :title="title" :data-gallery="'portfolio-gallery-' + category"
                    class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                <router-link class="details-link" title="Mais Detalhes"
                    :to="{ name: 'detalheProjeto', params: { idProjeto: id } }"> <i
                        class="bi bi-link-45deg"></i></router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GaleryCard",
    props: {
        id: {
            require: true,
            type: Number
        },
        title: {
            require: true,
            type: String
        },
        description: {
            require: true,
            type: String
        },
        image: {
            require: true,
            type: String
        },
        category: {
            require: true,
            type: Number
        }

    }
}
</script>