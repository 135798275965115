<template>
    <div class="testimonial-wrap">
        <div class="testimonial-item">
            <img :src="photo" class="testimonial-img" :alt="photo">
            <h3>{{ name }}</h3>
            <h4>{{ profession }}</h4>
            <div class="stars">
                <i class="bi bi-star-fill" v-for="star in stars" :key="star"></i>
            </div>
            <p>
                <i class="bi bi-quote quote-icon-left"></i>
                {{ message }}
                <i class="bi bi-quote quote-icon-right"></i>
            </p>
        </div>
    </div><!-- End testimonial item -->
</template>

<script>
export default {
    name: "FeedBackCard",

    props: {
        name: {
            require: true,
            type: String
        },
        profession: {
            require: true,
            type: String
        },
        message: {
            require: true,
            type: String
        },
        photo: {
            type: String
        },
        stars:{
            require: true,
            type: Number
        }
    }
}
</script>