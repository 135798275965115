import PartersService from "@/services/PartersService"

export default{
    state: {
        parters:[]
    },
    mutations: {
        setParters(context, data){
            context.parters = data
        }
    },
    actions: {
        getAllParters({commit}){
            return new Promise ((resolve, reject)=>{
                PartersService.listAll().then((response=>{
                    commit('setParters', response.data);
                    resolve(response.data)
                })).catch((error)=> reject(error))
            })
        }
    }
}