import FeedBacksService from "@/services/FeedbacksService";

export default{
    state: {
        feedbacks:[
        ]
    },
    getters: {
    },
    mutations: {
        setFeedbacks(context , data){
            context.feedbacks = data;
        }
    },
    actions: {
        getFeedbacks(context){
            context.dispatch('preloaderSet', true);
            FeedBacksService.getAllFeedbacks().then((response)=>{
                context.commit('setFeedbacks', response.data);
            }).finally(context.dispatch('preloaderSet', false))
        },

        newFeedback(context, data){
            return new Promise((resolve, reject) => {
                FeedBacksService.sendFeedback(data).then((e)=>{
                    context.dispatch('getFeedbacks');
                    context.dispatch('logout');
                    resolve(e)
                }).catch((error)=> {
                    context.dispatch('logout');
                    reject(error.response.data)
                })
            })
        }
    },
}