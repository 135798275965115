<template>
     <button :disabled="enabled" class="btn" @click="$emit('onclick')">
        {{ name }}
    </button>
</template>

<script>
export default {
    name: "ButtonView",

    props: {
        name: {
            require: true,
            type: String
        },
        color: {
            require: true,
            type: String
        },
        enabled:{
            type: Boolean
        }
    }
}
</script>