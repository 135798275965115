<template>
  <!-- ======= Our Team Section ======= -->
  <section id="team" class="team">
    <div class="container" data-aos="fade-up">

      <div class="section-header">
        <h2>Nosso time</h2>
        <p>Conheça o nosso time de espealista que iram atende-lo(a).</p>
      </div>
      <div class="row gy-5">
        <div class="col-lg-4 col-md-6 member" data-aos="fade-up" data-aos-delay="100" v-for="colaborador in colaboradores"
          :key="colaborador.id">

          <TeamCardView :name="colaborador.name" :profession="colaborador.profession"
            :description="colaborador.description" :photo="colaborador.photo" :twitter="colaborador.twitter"
            :facebook="colaborador.facebook" :instagram="colaborador.instagram"
            :linkedin="colaborador.linkedin" />

        </div><!-- End Team Member -->

      </div>

    </div>
  </section><!-- End Our Team Section -->
</template>

<script>
import { computed, onMounted } from 'vue';
import TeamCardView from './TeamCardView.vue';
import { useStore } from 'vuex';

export default {
  name: "TeamView",

  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch('getColaboradores')
    })

    return {
      colaboradores: computed(() => store.state.team.colaboradores),
    }
  },

  components: {
    TeamCardView
  }
}
</script>