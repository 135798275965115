import MensagemService from "@/services/MensagemService";

export default {
    state: {

    },
    getters: {
    },
    mutations: {

    },
    actions: {
        sendMensagem(context, data) {

            context.dispatch('preloaderSet', true);

            return new Promise((resolve, reject) => {
                MensagemService.send(data).then((response) => {
                  
                    resolve(response)

                }).catch((error) => {
                    reject(error)

                })
            }).finally(context.dispatch('preloaderSet', false))

        }
    }
}