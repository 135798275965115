import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import auth from "@/firebase/index"
import router from "@/router";

export default{
    state: {
        isAuthenticated: false,
        userData:{}
    },
    getters: {
    },
    mutations: {
        setUserData(context , data){
            const {displayName, email ,photoURL } = data
            context.userData = {
                name: displayName,
                email: email,
                photoURL: photoURL
            };
            context.isAuthenticated = true;
        },

        logout(context){
            context.isAuthenticated = false;
            context.userData = {}
        }

    },
    actions: {
        login({commit}){
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider).then((result)=>{
                commit('setUserData', result.user);
                router.push({name: 'feedback'});
            }).catch(()=>{
                
            })
        },

        logout({commit}){
            signOut(auth).then(() => {
                commit('logout');
                router.push({name: 'home'});
              })
        }
    }
}