<template>
  <div class="about">
    <Breadcrub title="Sobre Nós" pageName="Sobre Nós"/>

  </div>

  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">

      <div class="row position-relative">

        <div class="col-lg-7 about-img" :style="{ backgroundImage: 'url(' + require('@/assets/img/about.jpg') + ')' }"></div>

        <div class="col-lg-7">
          <h2>{{ title }}</h2>
          <div class="our-story">
            <h4>Desde 2018</h4>
            <h3>Nossa história</h3>
            <p>A Pconsult & Sousa SST é uma empresa de consultoria especializada em prevenção e combate a incêndios e
              Segurança do trabalho.
              Nós oferecemos uma ampla gama de serviços para ajudar empresas e organizações a garantir
              a segurança de suas instalações e proteger seus ativos contra incêndios.</p>
            <ul>
              <li><i class="bi bi-check-circle"></i> <span>Análise de risco</span></li>
              <li><i class="bi bi-check-circle"></i> <span>Plano de prevenção</span></li>
              <li><i class="bi bi-check-circle"></i> <span>Projeto de proteção contra incêndio</span></li>
              <li><i class="bi bi-check-circle"></i> <span>Treinamento e Simulação</span></li>
              <li><i class="bi bi-check-circle"></i> <span>Auditoria</span></li>
            </ul>
            <p>Com a Pconsult & Sousa SST, você pode ter a tranquilidade de saber que está trabalhando com uma equipe
              experiente e dedicada, comprometida em garantir a segurança de sua empresa e funcionários.
              Entre em contato conosco hoje para saber mais sobre nossos serviços de consultoria contra incêndio e
              Segurança do Trabalho.</p>

          </div>
        </div>

      </div>

    </div>
  </section>
  <!-- End About Section -->
  <StartsView />
  <TeamView />
</template>

<script>
import Breadcrub from '@/components/breadcrub/Breadcrub.vue';
import TeamView from '@/components/team/TeamView.vue'
import StartsView from '@/components/starts/StartsView.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  name: "SobreNos",

  setup(){
    const store = useStore()

    return{
      title: computed(() => store.state.home.dataSite.title),
    }
  },

  components: {
    Breadcrub,
    TeamView,
    StartsView

  }
}
</script>