<template>
    <!-- ======= Projetos Section ======= -->
    <section  id="constructions" class="constructions">
        <div class="container" data-aos="fade-up">

            <div class="section-header">
                <h2>Nossos Trabalhos</h2>
                <p>Conheça alguns dos nossos projetos concluídos.</p>
            </div>

            <div class="row gy-4">

                <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200" v-for="projeto in projetos"
                    :key="projeto.title">
                    <project-view :title="projeto.title" :description="projeto.description" :idProject=projeto.id
                        :image="[projeto.galerias.length > 0 ? projeto.galerias[0].url : require('@/assets/img/constructions-1.jpg')]">
                    
                    </project-view>
                </div>

            </div>

        </div>
        
    </section><!-- End Constructions Section -->
</template>

<script>
import { computed, onMounted } from 'vue';
import ProjectView from './partials/ProjectView.vue';
import { useStore } from 'vuex';

export default {
    name: "ProjectsView",

    setup() {
        const store = useStore()

        onMounted(() => {
            store.dispatch('getProjetos')
        })
        return {
            projetos: computed(() => store.state.projetos.projetos)
        }
    },
    components: {
        ProjectView
    }
}
</script>