<template>
    <!-- ======= Services Section ======= -->
    <section v-if="listServices != '' " id="services" class="services section-bg">
        <div class="container" data-aos="fade-up">

            <div class="section-header">
                <h2>Nossos Serviços</h2>
                <p>Conheça alguns dos nossos serviços prestados e saiba mais sobre cada um deles!</p>
            </div>

            <div class="row gy-4">

                <div v-for="service in listServices " :key="service.id"  class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                   <ServicesCard
                     :title="service.title"
                     :description="service.description"
                     :icone="service.icon"
                     :url="service.url"
                   />
                </div><!-- End Service Item -->

            </div>

        </div>
    </section><!-- End Services Section -->
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import ServicesCard from './ServicesCard.vue';

export default {
    name: "ServicesView",


    setup() {
        const store = useStore()

        onMounted(() => {
            store.dispatch('getServices');
        })

        return {
            listServices: computed(() => store.state.servicos.servicos),
        }
    },

    components:{
        ServicesCard
    }
}
</script>