import GaleriaService from "@/services/GaleriaService";

export default{
    state: {
        galeria:[
        ]
    },
    getters: {
    },
    mutations: {
        setGaleria(context , data){
            context.galeria = data;
        }
    },
    actions: {
        getGalerias(context){
            context.dispatch('preloaderSet', true);
            GaleriaService.listAll().then((response)=>{
                context.commit('setGaleria', response.data);
            }).finally(context.dispatch('preloaderSet', false))
        }
    }
}