<template>
    <div class="card clientes-item">
        <img  class="img" :src="url" :alt="id">
    </div>
</template>

<script>

export default{
    name:"PartersItem",
    props:{
        url:{  
            type: String   
        },
        id:{  
            type: Number   
        },
    }
}
</script>

<style>
.img{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    height: 150px;
}
.clientes-item{
    border-radius: 20px;
}
</style>